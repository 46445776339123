import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import "../../styles/backend.scss"
import TechHeader from "../../images/header-za-tehnologiju.png";
import toaster from "toasted-notes";
import 'toasted-notes/src/styles.css';

const allowedTypes = /\bdoc\b|\bdocx\b|\bpdf\b|\bzip\b|\brar\b/;

export default class BackEndDeveloperPage extends React.Component {
    state = {
        contactType: '',
        name: '',
        phone: '',
        email: '',
        message: '',
        selectedFile: '',
        outPutText: ''


    }
    handleChange = (e) => {
        switch (e.target.name) {
            case 'selectedFile':
                this.setState({ selectedFile: e.target.files[0] });
                this.setState({ outPutText: e.target.files[0].name});
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }
    resetInput = () => {
        this.outPutText= ''
    }
    handleSubmit = (formValues) => {
        const { contactType, name, phone, email, message } = formValues;
        const { selectedFile } = this.state;

        let formData = new FormData();
        formData.append('contactType', contactType);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('selectedFile', selectedFile);
        axios.post("https://backend.s7codedesign.com", formData)

        .then(function (response) {
            console.log("response", response);
            if (response.status == 200) {
                toaster.notify("You application has been successfully sent. We will answer you as soon as possible.", {
                    duration: 3000,
                    position: "top-right"
                  });
            }

        })
        .catch(function (error) {
            toaster.notify("Application sending fail. Our develop team is working to fix a problem.", {
                duration: 3000,
                position: "top-right"
              });
        })

    }
    render() {
        return (
            <Layout>
                <SEO title="Back End Developer" keywords={[`S7 Design`, `Bla..`, `Bla....`]} />
                <div className="backend-main" style={{ backgroundImage: `url(${TechHeader})` }}>
                    <div className="container">
                        <div className="row justify-content-center we-are-hiring">
                            <div className="col-md-10 col-12">
                                <h1 className="text-center">We are hiring!</h1>
                                <p className="text-center">We are looking for employees who is professional,
                                    dedicated, cooperative and ready for teamwork.
                                </p>
                            </div>
                        </div>
                        <div className="row backend-main--skills">
                            <h2 className="text-center">PHP Developer</h2>
                            <div className="w-100 height"></div>
                            <div className="checked col-md-6 col-12">
                                <h3>Requirements:</h3>
                                <ul>
                                    <li><span></span>1+ year of experience working in a professional software development environment</li>
                                    <li><span></span>Experience with PHP, MySQL , Git</li>
                                    <li><span></span>Good knowledge of OOP</li>
                                    <li><span></span>Familiar experience with Linux (Ubuntu)</li>
                                    <li><span></span>Familiar with Laravel, Symfony</li>
                                </ul>
                            </div>
                            <div className="checked col-md-6 col-12">
                                <h3>What we offer:</h3>
                                <ul>
                                    <li><span></span>Opportunity to work in a young, constructive and agile team</li>
                                    <li><span></span>Competitive salary</li>
                                    <li><span></span>Learning and development opportunities</li>
                                </ul>
                            </div>
                            <div className="w-100 height"></div>
                            <div className="reasons offset-md-1 col-md-10 col-12">
                                <h3>Reasons to join us:</h3>
                                <ul>
                                    <li><span></span>Good balance between work and life (eight hour work day, one hour for lunch break,
                                        regular holidays, flexible working hours</li>
                                    <li><span></span>Work on challenging and creative project for clients from Serbia and Europe</li>
                                    <li><span></span>Possibility of continuous development and stimulating income</li>
                                    <li><span></span>Cooperation in small and professional team with young people</li>
                                    <li><span></span>Fair salary depending on your experience, shown initiative, and contribution to the team
                                        and projects</li>
                                    <li><span></span>Reinforce your own skills on dynamic way</li>
                                </ul>
                                <p className="text-center pointed">We look forward to see you soon!</p>

                                <Formik
                                    initialValues={{contactType: 'Official website: BackEnd developer form', name: '', email: '', phone: '', message:'', selectedFile: '' }}
                                    validate={values => {
                                        let errors = {};


                                        // email
                                        if (!values.email) {
                                            errors.email = 'Email field is required';
                                        } else if (
                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                        ) {
                                            errors.email = 'Invalid email address';
                                        }
                                        // name
                                        if (!values.name) {
                                            errors.name = 'Name field is required';
                                        } else if (
                                            !/^[A-ZšđčćžŠĐČŽĆ ]{2,}$/i.test(values.name)
                                        ) {
                                            errors.name = 'Name must contain at least 2 letters.';
                                        }
                                        // phone
                                        if (values.phone && !/^[0-9._%+-]{6,}$/i.test(values.phone)) {
                                            errors.phone = 'Invalid phone number format.';
                                        }
                                        // message
                                        if (!values.message) {
                                            errors.message = 'Message field is required';
                                        } else if (
                                            !/^[A-ZšđčćžŠĐČŽĆ 0-9.,;:" '_%+-]{10,}$/i.test(values.message)
                                        ) {
                                            errors.message = 'Please fill up message field';
                                        }
                                        // files
                                        const {selectedFile} = this.state;

                                        if (!selectedFile) {
                                            errors.selectedFile = 'File attachment is mandatory';
                                        } else if (
                                            selectedFile && !selectedFile.name.split('.')[1].match(allowedTypes)
                                        ) {
                                            errors.selectedFile = 'Wrong file format.';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setTimeout(() => {
                                            setSubmitting(false);
                                            this.handleSubmit(values);
                                            resetForm();
                                            this.setState({outPutText: ''})
                                        }, 400);
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <Field type="hidden" name="contactType" className="invisible-field"/>
                                            <div className="form-row">
                                                <div className="form-group w-100 position-relative">
                                                    <label>NAME</label>
                                                    <Field type="text" name="name"  placeholder="" autoFocus/>
                                                    <ErrorMessage name="name" component="span" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6 col-12 position-relative">
                                                    <label>PHONE</label>
                                                    <Field type="text" name="phone"  placeholder=""/>
                                                    <ErrorMessage name="phone" component="span" />
                                                </div>
                                                <div className="form-group col-md-6 col-12 position-relative">
                                                    <label>EMAIL</label>
                                                    <Field type="email" name="email" placeholder=""/>
                                                    <ErrorMessage name="email" component="span" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group w-100 position-relative">
                                                    <label>WE'RE CURIOS TO FIND OUT MORE ABOUT YOU!</label>
                                                    <Field name="message" component="textarea" rows="1" placeholder="" />
                                                    <ErrorMessage name="message" component="span" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="attach-file position-relative">
                                                    <input id="file" name="selectedFile" type="file" onChange={(event) =>{this.handleChange(event)}} />
                                                    <label for="file" className="icon-attach custom-file-label text-uppercase"> attach your CV (doc, docx, pdf, zip, rar)</label>
                                                    {/* { this.state.selectedFile && <ErrorMessage name="selectedFile" component="span" />} */}
                                                    { this.state.selectedFile && !this.state.selectedFile.name.split('.')[1].match(allowedTypes) && (<div className="customErrorMsg">Wrong file format.</div>)}
                                                    { !this.state.selectedFile ? (<div className="customErrorMsg">File attachment is mandatory</div>) : ''}
                                                    { this.state.outPutText && (<div className="fileNameText">{this.state.outPutText}</div>)}
                                                </div>
                                            </div>
                                            <div className="form-row justify-content-end">
                                                <button className="btn" type="submit" disabled={isSubmitting}>APPLY NOW</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}